import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { environment } from '@env';
import { CrudAbstract } from '@core/abstracts/crud.abstract';
import { SubjectModel } from '@core/models/api/response/subject.model';
import { ListResponse } from '@core/models/api/response/list.response.model';

const params: any = { page_size: 50 };

@Injectable({
  providedIn: 'root'
})
export class SubjectsService extends CrudAbstract<SubjectModel> {

  subjects$: Observable<ListResponse<SubjectModel>> = this.http
    .get<ListResponse<SubjectModel>>(`${environment.jooApiBaseUrlV1}/matrix/subjects/`, { params })
    .pipe(shareReplay(1));

  constructor(
    protected http: HttpClient
  ) {
    super('subjects', http);
  }
}
