import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiAbstract } from './api.abstract';
import { ListResponse } from '@core/models/api/response/list.response.model';
import { CrudInterface } from './crud.interface';

export abstract class CrudAbstract<T> extends ApiAbstract implements CrudInterface<T> {

  constructor(
    protected uri: string,
    protected http: HttpClient,
    protected version?: 'v1' | 'v2'
  ) {
    super(uri);
  }

  fetch(params?: any): Observable<T[]> {
    return this.http.get<T[]>(this.version === 'v2' ? this.getUrlV2() : this.getJooApiCoursesUrl(), { params });
  }

  list(params?: any): Observable<any> {
    return this.http.get<ListResponse<T>>(this.version === 'v2' ? this.getUrlV2() : this.getJooApiCoursesUrl(), { params });
  }

  post(payload: T): Observable<T> {
    return this.http.post<T>(this.version === 'v2' ? this.getUrlV2() : this.getJooApiCoursesUrl(), payload);
  }

  get(id: number): Observable<T> {
    return this.http.get<T>(this.version === 'v2' ? this.getUrlV2(id) : this.getJooApiCoursesUrl(id));
  }

  put(id: number, payload: T): Observable<T> {
    return this.http.put<T>(this.version === 'v2' ? this.getUrlV2(id) : this.getJooApiCoursesUrl(id), payload);
  }

  patch(id: number, payload: T): Observable<T> {
    return this.http.patch<T>(this.version === 'v2' ? this.getUrlV2(id) : this.getJooApiCoursesUrl(id), payload);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.version === 'v2' ? this.getUrlV2(id) : this.getJooApiCoursesUrl(id));
  }
}
