const core = {
  all: 'Все',
  apply_changes: 'Применить фильтры',
  creation: 'Добавление',
  comments: 'Комментарий',
  duration: 'Продолжительность',
  editing: 'Редактирование',
  expert: 'Эксперт',
  experts: 'Эксперты',
  heading: 'Заголовок',
  headings: 'Заголовки',
  hide_filter: 'Скрыть фильтры',
  kk: 'Қазақша',
  moderator: 'Модератор',
  moderators: 'Модераторы',
  module: 'Модуль',
  modules: 'Модули',
  name_kk: 'Название на каз.',
  name_ru: 'Название на рус.',
  order: 'Позиция',
  original_question: 'Оригинальный вопрос',
  question_order: 'Заказ',
  question_orders: 'Заказы',
  question_count: 'Количество вопросов',
  reset_filters: 'Очистить фильтры',
  ru: 'Русский',
  search: 'Поиск',
  show_filter: 'Показать фильтры',
  subject: 'Предмет',
  subjects: 'Предметы',
  subtheme: 'Под тема',
  subthemes: 'Под темы',
  testolog: 'Тестолог',
  testologs: 'Тестологи',
  theme: 'Тема',
  themes: 'Темы',
  video_kk: 'Видео на каз.',
  video_ru: 'Видео на рус.',
  weight_test: 'Вес тест',
  weight_joo: 'Вес joo',
  description_kk: 'Описание на каз.',
  description_ru: 'Описание на рус.',

  date: 'Дата',
  text: 'Текст',
  questions: 'Вопросы',
  answers: 'Ответы',
  lang: 'Язык',
  mode: 'Режим',
  type: 'Тип',
  complexity: 'Сложность',
  status: 'Статус',
  source: 'Источник',
  create: 'Создать',
  add: 'Добавить',
  edit: 'Редактировать',
  save: 'Сохранить',
  delete: 'Удалить',
  cancel: 'Отмена',
  empty_list: 'Список пуст',
  preview: 'Предпросмотр',
};

export default core;
