import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {shareReplay} from "rxjs/operators";

import {ApiAbstract} from '@core/abstracts/api.abstract';

@Injectable({
  providedIn: 'root'
})
export class WeeklyTestConstantsService extends ApiAbstract {

  constants$ = this.http.get<any>(this.getUrl()).pipe(shareReplay(1));

  constructor(
    protected http: HttpClient,
  ) {
    super('weekly-tests/constants');
  }
}
