import {LocaleModel} from '@core/models/local/locale.model';
import core from './core/ru';

const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    core,
    errors: {
      title: 'Ошибка',
      network_error: 'Возникла ошибка сети. Пожалуйста, обратитесь за помощью к системному администратору.',
      server_error: 'Возникла ошибка сервера. Пожалуйста, обратитесь за помощью к системному администратору.',
      no_system_access_error: 'Ваш аккаунт не имеет доступа к данной системе.',
      not_found: 'Не найдено',
    },
    auth: {
      title: 'Авторизация',
      username: 'Логин',
      password: 'Пароль',
      sign_in: 'Войти',
      sign_out: 'Выйти',
    },
    testolog: {
      questions_original: {
        list: {
          title: 'Оригинальные вопросы',
          developing: 'В разработке',
          on_check: 'На проверке',
          returned: 'Отклоненные',
          accepted: 'Принятые',
        },
      },
      headings_original: {
        list: {
          title: 'Групповые вопросы',
          developing: 'В разработке',
          on_check: 'На проверке',
          returned: 'Отклоненные',
          accepted: 'Принятые',
        },
      },
    },
    expert: {
      questions_original: {
        list: {
          title: 'Оригинальные вопросы',
          waiting_for_checking: 'Ожидающие проверки',
          my_questions: 'Мои вопросы',
          rejected: 'Отклоненные',
          accepted: 'Принятые',
        },
      },
      headings_original: {
        list: {
          title: 'Групповые вопросы',
          own: 'Мои вопросы',
          waiting: 'Ожидающие проверки',
          rejected: 'Отклоненные',
          accepted: 'Принятые',
        },
      },
    },
    moderator: {
      questions: {
        list: {
          title: 'Все вопросы',
          show_filters: 'Показать фильтры',
          hide_filters: 'Скрыть фильтры',
          reset_filters: 'Очистить фильтры',
          apply_changes: 'Применить изменения',
        },
      },
      headings: {
        list: {
          title: 'Все заголовки',
          show_filters: 'Показать фильтры',
          hide_filters: 'Скрыть фильтры',
          reset_filters: 'Очистить фильтры',
          apply_changes: 'Применить изменения',
        },
      },
      question_orders: {
        list: {
          title: 'Все заказы',
        },
      },
      subjects: {
        list: {
          title: 'Список предметов'
        },
        edit: {
          title: {
            creation: 'Создание предмета',
            editing: 'Редактирование предмета',
          },
        }
      },
      themes: {
        list: {
          title: 'Список тем'
        },
        edit: {
          title: {
            creation: 'Создание темы',
            editing: 'Редактирование темы',
          },
        }
      },
      subthemes: {
        list: {
          title: 'Список подтем'
        },
        edit: {
          title: {
            creation: 'Создание подтемы',
            editing: 'Редактирование подтемы',
          },
        }
      },
      modules: {
        list: {
          title: 'Список модулей'
        },
        edit: {
          title: {
            creation: 'Создание модуля',
            editing: 'Редактирование модуля',
          },
          questions: 'Вопросы',
          class_works: 'Классная работа',
          examples: 'Примеры',
        }
      },
      profiles: {
        list: {
          title: 'Список пользователей'
        },
        edit: {
          title: 'Просмотр пользователя',
          expert_subjects_list: 'Предметы эксперта'
        }
      },
    },
    shared: {
      question_edit: {
        main: {
          title: 'Основное',
        },
        comments: {
          title: 'Комментарий эксперта'
        },
        answers: {
          correct: 'Правильный'
        },
        extra: {
          title: 'Дополнительно',
        },
        solutions: {
          title: 'Подсказки',
        },
        footer: {
          change_status: 'Изменить статус',
          activate: 'Активировать',
          return_to_testolog: 'Возвратить тестологу',
          submit_to_expert: 'Отправить к эксперту',
        },
      }
    }
  }
};

export default localeRu;
