import {LangType} from '@core/types/lang.type';

export class Init {
  static readonly type = '[App] Init';
}

export class UpdateLang {
  static readonly type = '[App] UpdateLang';

  constructor(public lang: LangType) {
  }
}

export class UpdateConstants {
  static readonly type = '[App] UpdateConstants';
}

export class GetCustomTestsConstants {
  static readonly type = '[App] GetCustomTestsConstants';
}

export class UpdateSubjects {
  static readonly type = '[App] UpdateSubjects';

  constructor(public subjects: any) {
  }
}
