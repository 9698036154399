import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@core/abstracts/api.abstract';
import { ConstantsModel } from '@core/models/api/response/constants.model';
import { environment } from '@env';


@Injectable({
  providedIn: 'root'
})
export class ConstantsService extends ApiAbstract {

  constructor(
    protected http: HttpClient
  ) {
    super('courses/constants');
  }

  fetch(): Observable<ConstantsModel> {
    return this.http.get<ConstantsModel>(this.getUrlV2());
  }

  fetchMatrix(): Observable<ConstantsModel> {
    let url = `${environment.jooApiBaseUrlV1}/matrix/constants/`;
    return this.http.get<ConstantsModel>(url);
  }

  fetchTrainer(): Observable<ConstantsModel> {
    let url = `${environment.jooApiBaseUrlV1}/trainer/constants/`;
    return this.http.get<ConstantsModel>(url);
  }

}
