import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@core/abstracts/api.abstract';
import { CustomTestConstantsModel } from '@core/models/api/response/custom-test-constants.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CustomTestsConstantsService extends ApiAbstract {

  constructor(
    protected http: HttpClient
  ) {
    super('courses/custom-tests/constants');
  }

  fetch(): Observable<CustomTestConstantsModel> {
    return this.http.get<CustomTestConstantsModel>(this.getUrl());
  }
}
