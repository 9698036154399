import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {TranslateService} from '@ngx-translate/core';

import localeKk from '@assets/i18n/kk';
import localeRu from '@assets/i18n/ru';

import {LangType} from '@core/types/lang.type';
import {ConstantsService} from '@core/services/api/constants.service';
import {ConstantsModel} from '@core/models/api/response/constants.model';
import {AuthState} from '@core/states/auth/auth.state';
import {CustomTestsConstantsService} from '@core/services/api/custom-test-constants.service';
import {CustomTestConstantsModel} from '@core/models/api/response/custom-test-constants.model';
import {WeeklyTestConstantsService} from '@core/services/api/weekly-test-constants.service';

import {GetCustomTestsConstants, Init, UpdateConstants, UpdateLang, UpdateSubjects} from './actions';

interface AppStateModel {
  lang: LangType;
  constants: ConstantsModel;
  customTestConstants: CustomTestConstantsModel;
  subjects: any;
}

const defaults: AppStateModel = {
  lang: null,
  constants: null,
  customTestConstants: null,
  subjects: null
};

@State<AppStateModel>({
  name: 'app',
  defaults
})
@Injectable()
export class AppState {

  @Selector()
  static lang({lang}: AppStateModel): LangType {
    return lang;
  }

  @Selector()
  static constants({constants}: AppStateModel): ConstantsModel {
    return constants;
  }

  @Selector()
  static customTestConstants({customTestConstants}: AppStateModel): CustomTestConstantsModel {
    return customTestConstants;
  }

  @Selector()
  static subjects({subjects}: AppStateModel): any {
    return subjects;
  }

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private constantsService: ConstantsService,
    private customTestsConstantsService: CustomTestsConstantsService,
    private weeklyTestConstantsService: WeeklyTestConstantsService,
  ) {
  }

  @Action(Init)
  Init({getState, patchState}: StateContext<AppStateModel>) {
    let lang = getState().lang;
    if (!lang) {
      lang = 'kk';
    }

    patchState({lang});

    this.translateService.addLangs(['kk', 'ru']);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);

    [localeKk, localeRu].forEach(({lang, data}) => {
      this.translateService.setTranslation(lang, data, true);
    });
  }

  @Action(UpdateLang)
  UpdateLang({getState, patchState}: StateContext<AppStateModel>, {lang}: UpdateLang) {
    if (lang !== getState().lang) {
      patchState({lang});
      this.translateService.use(lang);

      if (this.store.selectSnapshot(AuthState.token)) {
        this.store.dispatch(UpdateConstants);
        if (this.store.selectSnapshot(AuthState.isCustomTestManager)) {
          this.store.dispatch(GetCustomTestsConstants);
        }
      }
    }
  }

  @Action(UpdateConstants)
  UpdateConstants({patchState}: StateContext<AppStateModel>) {
    return Promise.all([
      this.constantsService.fetch().toPromise(),
      this.constantsService.fetchMatrix().toPromise(),
      this.constantsService.fetchTrainer().toPromise()
    ]).then(([courseConst, matrixConst, trainerConst]) => {
      patchState({constants: {...courseConst, ...matrixConst, ...trainerConst}});
    });
  }

  @Action(GetCustomTestsConstants)
  GetCustomTestsConstants({patchState}: StateContext<AppStateModel>) {
    return this.customTestsConstantsService.fetch()
      .toPromise()
      .then(customTestConstants => {
        patchState({customTestConstants});
      });
  }

  @Action(UpdateSubjects)
  UpdateSubjects({patchState}: StateContext<AppStateModel>, {subjects}: UpdateSubjects) {
    patchState({subjects});
  }
}
